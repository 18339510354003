import React from 'react';
import { graphql } from 'gatsby';
import Container from 'components/Container';
import Block from 'components/Block';
import PageHeader from 'components/PageHeader';
import faqTransformer from 'src/transformers/FaqQuery_Faq';
import FaqAccordion from 'components/accordions/FaqAccordion';
import GenericGridBlock from 'components/GenericGridBlock';
import GeneralTile from 'src/components/tiles/GeneralTile';
import Breadcrumbs, { Props as BreadcrumbsProps } from 'src/components/Breadcrumbs';
import CtaBlock from 'components/CtaBlock';
import ServicesBlock from 'src/components/ServicesBlock';
import FeatureCopyBlock from 'src/components/FeatureCopyBlock';
import CtaButton from 'src/components/CtaButton';
import Spacer from 'src/components/Spacer';
import SubNavMenu, { Props as SubNavMenuProps } from 'src/components/SubNavMenu';
import { resolveUrl } from 'src/helpers';
import CaseStudyQuery_CaseStudy from 'src/transformers/CaseStudyQuery_CaseStudy';
import PostQuery_Post from 'src/transformers/PostQuery_Post';
import AwardsBlock from 'src/components/AwardsBlock';
import AwardQuery_Award from 'src/transformers/AwardQuery_Award';
import isNotNull from 'src/types/guards/isNotNull';
import useAllServices from 'src/components/ServicesBlock/useAllServices';
import Seo from 'src/lib/components/Seo';
import CaseStudiesBlock from 'components/CaseStudiesBlock';
import ContentParser from 'components/ContentParser';
import BasicQuery_SitePage from 'src/transformers/BasicQuery_SitePage';

interface Props {
  data: GatsbyTypes.ServiceSingleAndAssociatedPostsQuery,
  // pageContext: { serviceId: string },
}

export const query = graphql`
  query ServiceSingleAndAssociatedPosts($serviceId: String, $slug: String) {
    wpService(id: { eq: $serviceId }) {
      ...ServiceBasic
      acf {
        copyBlock1
        linkedFaqs {
          ... on WpFaq {
            ...FaqFragment
          }
        }
        linkedCaseStudies {
          ... on WpCaseStudy {
            ...CaseStudyWithThumbnail
          }
        }
        linkedSubServices {
          ... on WpSubService {
            ...SubServiceBasic
          }
        }
        linkedAwards {
          ... on WpAward {
            ...AwardWithThumbnail
          }
        }
      }
    }
    relatedPosts: allWpPost(
      filter: {categories: {nodes: {elemMatch: {slug: {eq: $slug}}}}}
      sort: {fields: date, order: DESC}
      limit: 3
    ) {
      nodes {
        ...PostWithThumbnail
      }
    }
  }
`;


const ServicePage: React.FC<Props> = ({ data }) => {
  const service = data.wpService;
  const relatedPostsRaw = data.relatedPosts.nodes;

  const allServices = useAllServices();

  const pageData = BasicQuery_SitePage(service || {});
  const relatedPosts = Array.from(relatedPostsRaw, PostQuery_Post).filter(isNotNull);

  const faqLists = service?.acf?.linkedFaqs;
  const firstFaqList = faqLists && faqLists?.length > 0 ? faqLists[0] : undefined;
  const faqs = faqTransformer(firstFaqList);

  const caseStudiesRaw = service?.acf?.linkedCaseStudies || [];
  const caseStudies = Array.from(caseStudiesRaw, CaseStudyQuery_CaseStudy).filter(isNotNull);

  const awardsRaw = service?.acf?.linkedAwards || [];
  const awards = Array.from(awardsRaw, AwardQuery_Award).filter(isNotNull);

  const breadcrumbs: BreadcrumbsProps['items'] = [
    { label: 'Services', url: resolveUrl.service('') },
    { label: pageData.navTitle },
  ];

  const subNavItems: SubNavMenuProps['items'] = service?.acf?.linkedSubServices?.map((subService) => {
    const subServicePageData = BasicQuery_SitePage(subService || {});
    return {
      label: subServicePageData.navTitle,
      url: resolveUrl.subService(subServicePageData.slug, pageData.slug),
    };
  }) || [];

  return (
    <>
      <Seo
        title={pageData.metaTitle}
        // titlePath="Services"
        description={pageData.metaDescription}
      />
      <PageHeader
        category="Services"
        h1={pageData.h1}
        backgroundText={pageData.contentHtml}
      />
      {subNavItems.length > 0 && (
        <Block darkBg type="noPadding">
          <SubNavMenu items={subNavItems} />
        </Block>
      )}

      <Container readableWidth>
        <Breadcrumbs items={breadcrumbs} />

        <Block>
          <ContentParser>{pageData.contentHtml}</ContentParser>
          <Spacer mb={4} />
          <CtaButton type="primary" />
        </Block>
      </Container>

      {awards.length > 0 && (
        <Block contain highlightBg>
          <AwardsBlock overrideAwards={awards} />
        </Block>
      )}

      {faqs && faqs.length > 0 && (
        <Block contain readableWidth>
          <FaqAccordion faqs={faqs} />
        </Block>
      )}

      {caseStudies?.length > 0 && (
        <Block darkBg>
          <Container yPadding={0}>
            <CaseStudiesBlock
              title={`${service?.title || ''} case studies`}
              smallTitle
              caseStudies={caseStudies}
            />
          </Container>
        </Block>
      )}

      {service?.acf?.copyBlock1 && (
        <Block customConfig={{ py: 0, pt: 3 }}>
          <Container yPadding={0}>
            <FeatureCopyBlock copyHTML={service?.acf?.copyBlock1} />
          </Container>
        </Block>
      )}

      <Block>
        <Container yPadding={0}>
          <ServicesBlock services={allServices} />
        </Container>
      </Block>

      {relatedPosts?.length > 0 && (
        <Block darkBg>
          <Container yPadding={0}>
            <GenericGridBlock title={`Latest ${pageData.navTitle} insight.`} smallTitle>
              {relatedPosts.slice(0, 3).map((post) => (
                <GeneralTile
                  key={post.title}
                  title={post.title}
                  image={post.featuredImage}
                  href={resolveUrl.post(post.slug)}
                />
              ))}
            </GenericGridBlock>
          </Container>
        </Block>
      )}

      <CtaBlock />
    </>
  );
};

export default ServicePage;
